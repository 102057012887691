
export default {
  name: 'update-modal-app',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    warningTitle: {
      type: String,
      default: '',
    },
    confirmLabel: {
      type: String,
      default: 'Да',
    },
    postponeLabel: {
      type: String,
      default: 'Нет',
    },
  },

  computed: {
    animationName () {
      return this.isMobile
        ? 'slide-bottom'
        : 'slide-right';
    },
  },
};
