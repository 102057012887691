import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a949bcec&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=a949bcec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a949bcec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzOfflineModal: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/offline-modal/index.vue').default,RzOffline: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/offline/index.vue').default,RzNotificationCenter: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/notification-center/index.vue').default,SnowEffect: require('/opt/razlet.kg/src/components/snow-effect.vue').default,BirthdayMessage: require('/opt/razlet.kg/src/components/birthday-message.vue').default,MainHeader: require('/opt/razlet.kg/src/components/main-header.vue').default,RzIconNew: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-new/index.vue').default,RzVerticalMenu: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/vertical-menu/index.vue').default,RzContainer: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/container/index.vue').default,RzDeviceView: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/device-view/index.vue').default,CallButton: require('/opt/razlet.kg/src/components/call-button.vue').default,FooterBlock: require('/opt/razlet.kg/src/components/footer-block.vue').default,UpdateModal: require('/opt/razlet.kg/src/components/update-modal.vue').default})
