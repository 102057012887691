export default {
  data () {
    return {
      bcUpdate: null,
      bindReload: this.reloadPage.bind(this),
      isUpdateModal: false,
      isReloading: false,
    };
  },

  computed: {
    updateMessage () {
      if (this.isApp) return this.$t('update.appMessage');
      return this.$t('update.message');
    },
    updateWarningMessage () {
      if (this.isApp || this.isMobile) return '';
      return this.isYandex
        ? this.$t('update.yandexWarning')
        : this.$t('update.warning');
    },
  },

  mounted () {
    this.bcUpdate = new BroadcastChannel('update');

    /* eslint-disable consistent-return */
    this.bcUpdate.onmessage = (event) => {
      if (event.data === 'UPDATE_AVALIABLE') return this.openUpdateModal();
      if (event.data === 'UPDATE_CANCEL') return this.closeUpdateModal();
      if (event.data !== 'UPDATE_CONFIRM') return null;
      if (this.isYandex) {
        window.open('about:blank', '_self').close();
      }
      this.closeUpdateModal();
      window.addEventListener('focus', this.bindReload);
    };
  },

  beforeDestroy () {
    this.bcUpdate.close();
    window.removeEventListener('focus', this.bindReload);
  },

  methods: {
    openUpdateModal () {
      this.isUpdateModal = true;
    },
    closeUpdateModal () {
      this.isUpdateModal = false;
    },
    postponeUpdate () {
      this.closeUpdateModal();
      this.bcUpdate.postMessage('UPDATE_CANCEL');
    },
    async startUpdate () {
      if (!('serviceWorker' in navigator)) return;

      this.closeUpdateModal();
      this.bcUpdate.postMessage('UPDATE_CONFIRM');


      const reg = await navigator.serviceWorker.getRegistration();

      if (reg && reg.waiting) {
        reg.waiting.postMessage({ type: 'SKIP_WAITING' });

        if (this.isYandex) reg.waiting.postMessage({ type: 'UNREGISTER' });
      }

      this.reloadPage();
    },
    reloadPage () {
      if (this.isReloading) return;
      this.isReloading = true;
      this.$forceNextTick(() => window.location.reload());
    },
  },
};
